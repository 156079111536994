function getSessionStorage (key) {
  let val = sessionStorage.getItem(key)
  try {
    return JSON.parse(val)
  } catch {
    return val
  }
}

const setting = {
  // 项目标题
  title: '开盖赢红包',
  // 红包接口 DouMi
  redEnvelopeApiType: `DouMi`,
  // 加载远程图
  loadRemotePic: true,
  // 更多好礼
  showMoreGift: true,
  // 埋点配置
  tracking: {
    url: process.env.VUE_APP_BASE_API + '/osa/logAgent/save',
    scene: {
      //页面进入前
      before: {
        turntable: {
          userVisit: 38,
          back: 17
        },
        // 谢谢惠顾
        thanksPatronize: {
          userVisit: 6,
          back () {
            sessionStorage.setItem('afterBack', 2)
            return 2
          }
        },
        // 后置
        redEnvelopes: {
          userVisit: 6,
          back () {
            let num = sessionStorage.getItem('isEnd') ? 8 : 1
            sessionStorage.setItem('afterBack', num)
            return getSessionStorage('isEnd') ? 8 : 1
          }
        },
        codeUsed: {
          userVisit: 42,
          back () {
            if (getSessionStorage('isMiddlePageDraw')) {
              //中红包抽电子奖back优化转盘
              let backValue = sessionStorage.getItem('backValue')

              if (backValue) {
                sessionStorage.setItem('afterBack', 'null')
                return 'null' // 这个back埋点只发送一次
              } else {
                sessionStorage.setItem('backValue', 5)
                sessionStorage.setItem('afterBack', 5)
                return 5
              }
            } else if (getSessionStorage('isMiddlePageEnter')) {
              //中红包back
              let backValue = sessionStorage.getItem('backValue')
              if (backValue) {
                sessionStorage.setItem('afterBack', 'null')
                return 'null' // 这个back埋点只发送一次
              } else {
                sessionStorage.setItem('backValue', 3)
                sessionStorage.setItem('afterBack', 3)
                return 3
              }
              // return 3
            } else if (getSessionStorage('isQRcodeEnd')) {
              sessionStorage.setItem('afterBack', 'null')
              return 'null'
            } else if (sessionStorage.getItem('thanks')) {
              // 谢谢惠顾页面点击抽电子奖，从电子奖页面返回到谢谢惠顾，最终重定向到codeUsed页面
              sessionStorage.removeItem('thanks')
              sessionStorage.setItem('afterBack', 6)
              return 6
            } else if (getSessionStorage('_isBack') == 'coupons') {
              //未中奖back转盘
              sessionStorage.setItem('afterBack', 11)
              return 11
            } else if (sessionStorage.getItem('showedThanksPatronize')) {
              //从谢谢惠顾返回到codeUsed页面
              sessionStorage.removeItem('showedThanksPatronize')
              sessionStorage.setItem('afterBack', 11)
              return 11
            } else {
              sessionStorage.setItem('afterBack', 'null')
              return 'null'
            }
          }
        }
      },
      //页面进入后
      after: {
        codeUsed: {
          userVisit: 2,
          back () {
            return sessionStorage.getItem('afterBack')
          }
        },
        redEnvelopes: {
          userVisit: 2,
          back () {
            return sessionStorage.getItem('afterBack')
          }
        },
        thanksPatronize: {
          userVisit: 2,
          back: 2
        }
      },
      //返回页面
      back: {},
      //页面所有接口请求后埋点
      nextRequest: {
        turntable: {
          userVisit: 39,
          back: 17
        }
      }
    },
    // 转换页面埋点
    transform (routeName) {
      // 活动已结束
      if (getSessionStorage('isEnd')) {
        if (routeName == 'codeUsed') {
          routeName = 'redEnvelopes'
        }
      }
      return routeName
    },
    hook () { }
  }
}

export default setting

if (process.env.NODE_ENV != 'production') {
  console.log(
    '%c开发环境输出以下配置信息，避免开发时遗漏',
    'color: red; font-size: 20px;'
  )
  console.table([
    {
      key: 'title',
      value: setting.title,
      description: '页面标题'
    },
    {
      key: 'redEnvelopeApiType',
      value: setting.redEnvelopeApiType,
      description: '红包接口类型'
    },
    {
      key: 'loadRemotePic',
      value: setting.loadRemotePic,
      description: '加载远程图'
    },
    {
      key: 'showMoreGift',
      value: setting.showMoreGift,
      description: '是否显示更多好礼'
    },
    {
      key: 'VUE_APP_BASE_API',
      value: process.env.VUE_APP_BASE_API,
      description: '接口地址'
    },
    {
      key: 'VUE_APP_QUESTION_URL',
      value: process.env.VUE_APP_QUESTION_URL,
      description: '问题咨询地址'
    },
    {
      key: 'VUE_APP_QUESTION_TITLE',
      value: process.env.VUE_APP_QUESTION_TITLE,
      description: '问题咨询CODE'
    },
    {
      key: 'VUE_APP_ROUTER_BASE',
      value: process.env.VUE_APP_ROUTER_BASE,
      description: '路由及打包目录'
    },
    {
      key: 'VUE_APP_BASE_PIC',
      value: process.env.VUE_APP_BASE_PIC,
      description: 'OSS地址'
    },
    {
      key: 'VUE_APP_PRIVACYSTATEMENT_URL',
      value: process.env.VUE_APP_PRIVACYSTATEMENT_URL,
      description: '隐私声明地址'
    }
  ])
  console.log('----------------------------')
}
